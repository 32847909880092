<template>
    <!-- 富文本 -->
    <div class="richtext" :style="{ 'background-color': this.richtextData.styletype.bgcolor }">
        <div style="min-height:20px;" v-html="richtextData.value"></div>
    </div>
</template>

<script>
import { reactive } from "vue";

export default {
    name: "richtextshow",
    props: ["richtextdata"],
    setup(props) {
        const richtextData = reactive(props["richtextdata"]);

        return {
            richtextData
        };
    },
    methods: {},
};
</script>

<style scoped>
.richtext {
    position: relative;
    margin: 0;
}

.richtext>>>p,
.richtext>>>ul,
.richtext>>>ol {
    position: relative;
    margin: 0;
}

.richtext>>>blockquote {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
}
</style>