<template>
    <div>
        <!-- 按钮-->
        <div style="text-align: center;"
            :style="{ 'height': buttonType.boxshadow != '' ? parseInt(styleType.btnheight.split(-2, 2)) + 5 + 'px' : '' }">
            <el-button :type="buttonData.styletype.btntype.type" :plain="buttonType.plain" :round="buttonType.round"
                @click="this.buttonflages != 'nosubmit' ? clickJumpurlEvent(buttonData.styletype.formdata.jumpurl) : ''" 
                :style="{
                    'font-size': styleType.fontsize, 'color': styleType.fontcolor.color,
                    'background-color': styleType.bgcolor.color, 'width': styleType.btnwidth,
                    'height': styleType.btnheight, 'border-color': styleType.bgcolor.color,
                    'box-shadow': buttonType.boxshadow != '' ? buttonType.boxshadow + '7e' : ''
                }">
                {{ buttonData.value }}
            </el-button>
        </div>
    </div>
</template>

<script>
import { reactive } from "vue";

export default {
    name: "buttonshow",
    props: ["buttondata","buttonflages"],
    setup(props) {
        const buttonData = reactive(props["buttondata"]);
        const styleType = buttonData.styletype;
        const buttonType = styleType.btntype;

        return {
            buttonData,
            styleType,
            buttonType
        };
    },
    methods: {
        clickJumpurlEvent(jumpurl) {
            if (jumpurl != '') {
                window.open(jumpurl,'_blank')
            }
        }
    },
    watch: {
        buttondata: {
            handler(newVal) {
                //监听form的数据变化
                this.buttonData = newVal;
                this.styleType = newVal.styletype;
                this.buttonType = newVal.styletype.btntype;

                // 监听-背景颜色的变化
                if (this.buttonType.boxshadow != '' && this.styleType.bgcolor.color != '') {
                    let array = this.buttonType.boxshadow.split(' ');
                    array[array.length - 1] = this.styleType.bgcolor.color;
                    this.buttonType.boxshadow = array.join(' ')
                }
            },
            deep: true,
        },
    },
};
</script>

<style scoped>
.show {
    background-color: #8888887e;
}
</style>