<template>
  <div :style="{'background-color': textData.styletype.bgcolor,}">
    <div class="padding-box-center-padding">
      <div
        :style="{
          'text-align': textData.styletype.textalign,
          'font-size': textData.styletype.fontsize,
          'font-weight': textData.styletype.fontweight,
          'color': textData.styletype.color,
        }"
      >
        {{ textData.value }}
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "textshow",
  props: ["textdata"],
  setup(props) {
    const textData = reactive(props["textdata"]);

    return {
      textData,
    };
  },
  methods: {},
};
</script>

<style scoped>
.padding-box-center-padding {
  padding: 10px 20px;
  
}
</style>

