<template>
    <!-- 表单 -->
    <div class="formtable-box">
        <div style="text-align: center;margin-bottom: 10px;" v-if="this.formdataData.valueisshow == '1'">
            <span>{{ this.formdataData.value }}</span>
        </div>
        <div>
            <el-form el-form ref="myform" :model="this.myform" :rules="this.formdataData.formdata.rules"
                label-position="top">
                <el-form-item>
                    <!-- 表单详情 -->
                    <div v-for="(item, index) in this.formdataData.formdata.forminfo" :key="index">
                        <el-form-item
                            :label="this.formdataData.formdata.isshow == '1' ? (item.name != '' ? item.name : '题目名称') : ''"
                            :prop="'field' + item.key">
                            <!-- 输入框 -->
                            <div v-if="item.inputboxtype.type == 1"
                                :class="this.formdataData.formdata.isshow == '1' ? 'formtable-box-div-bottom' : 'formtable-box-div'">
                                <el-input v-model="item.value" :placeholder="'请输入' + item.name" clearable size="large">
                                </el-input>
                            </div>
                            <!-- 下拉框 -->
                            <div v-if="item.inputboxtype.type == 2"
                                :class="this.formdataData.formdata.isshow == '1' ? 'formtable-box-div-bottom' : 'formtable-box-div'">
                                <el-select v-model="item.value" :placeholder="'请选择' + item.name" style="width:100%"
                                    size="large">
                                    <el-option v-for="(item, index) in item.inputboxtype.options" :key="index"
                                        :label="item" :value="item" />
                                </el-select>
                            </div>
                            <!-- 多级联选 -->
                            <div v-if="item.inputboxtype.type == 3"
                                :class="this.formdataData.formdata.isshow == '1' ? 'formtable-box-div-bottom' : 'formtable-box-div'"
                                class="formtable-box-div-cascader">
                                <el-cascader v-model="item.value" :options="item.inputboxtype.options" style="width:100%"
                                    :placeholder="'请选择' + item.name" @change="handleChangeCascader" 
                                    size="large" />
                            </div>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item>
                    <!-- button按钮 -->
                    <div class="formtable-box-bottom">
                        <mybuttonshow :buttondata="formdataData.button"
                            @click="this.formflages != 'nosubmit' ? (this.mydisabled == false ? clickFormEvent(`myform`, `/admin/pages/formDataSave`) : '') : ''">
                        </mybuttonshow>
                    </div>
                </el-form-item>
            </el-form>
        </div>

    </div>
</template>

<script>
import mybuttonshow from "@/components/weipages/subshow/buttonshow/buttonshow.vue"
import { reactive } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";

export default {
    name: "formshow",
    props: ["formdata", 'formflages'],
    setup(props) {
        const formdataData = reactive(props["formdata"]);

        return {
            formdataData,
        };
    },
    data() {
        return {
            myform: {},

            mydisabled: false, // 防抖-前端维护
        }
    },
    methods: {
        // 提交表单事件
        clickFormEvent(formName, apiurl) {
            // 前端防抖
            this.mydisabled = true;

            var getdatas = this.$refs[formName].model;
            // 判断value值是否为地址数组
            let forminfo = this.formdataData.formdata.forminfo;
            forminfo.forEach(element => {
                if (element.inputboxtype.type == 3) {
                    if (getdatas['field' + element.key]) {
                        if (typeof getdatas['field' + element.key] != 'string') {
                            getdatas['field' + element.key] = getdatas['field' + element.key].join('-')
                        }
                    }
                }
            });

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    axios
                        .post(apiurl, getdatas, { headers: {} })
                        .then((response) => {
                            if (response.data.code === 0) {
                                ElMessage({
                                    type: "success",
                                    offset: 120,
                                    message: response.data.message,
                                    duration: 1000,
                                    onClose: () => {
                                        this.mydisabled = false;
                                    },
                                });
                            } else {
                                this.mydisabled = false;
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                            this.mydisabled = false;
                        });
                } else {
                    console.log("error submit!!");
                    this.mydisabled = false;
                    return false;
                }
            });
        },
    },
    watch: {
        formdata: {
            handler(newVal) {
                //监听form的数据变化
                this.formData = newVal;

                let getformdata = {};
                getformdata.form_id = newVal.form_id;

                newVal.formdata.forminfo.forEach(element => {
                    let getchartname = 'field' + element.key;
                    getformdata[getchartname] = element.value;
                });
                this.myform = getformdata;
            },
            deep: true,
        }
    },
    components: {
        mybuttonshow,
    },
};
</script>

<style scoped>
.formtable-box {
    padding: 10px 20px;
}

.formtable-box-div {
    margin-bottom: 20px;
}

.formtable-box-div-bottom {
    margin-bottom: 10px;
}


.formtable-box-bottom {
    margin-top: 30px;
}

.formtable-box :deep(.el-form--label-top .el-form-item__label) {
    padding: 0;
}
</style>