<template>
    <!-- 图片 -->
    <div style="text-align: center;" @click="this.pictureflages != 'nosubmit' ? clickJumpurlEvent(pictureData.formdata.jumpurl) : ''">
        <el-image :src="pictureData.value" :fit="fill" :style="{
            'width': pictureData.styletype.width,
            'height': pictureData.styletype.height,
            'vertical-align': 'top'
        }">
        </el-image>
    </div>
</template>

<script>
import { reactive } from "vue";

export default {
    name: "pictureshow",
    props: ["picturedata",'pictureflages'],
    setup(props) {
        const pictureData = reactive(props["picturedata"]);

        return {
            pictureData
        };
    },
    methods: {
        clickJumpurlEvent(jumpurl) {
            console.log(123123)
            if (jumpurl != '') {
                window.open(jumpurl,'_blank')
            }
        }
    },
};
</script>

<style scoped>
</style>